import { Injectable } from '@angular/core';
import { AlsdeUser } from '../models/alsde-user.model';
import { LocalStorageService } from './local-storage.service';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { IAlsdeBackground, AlsdeBackground } from '../models/background.model';
import { ApplicationWizard } from '../models/application-wizard.model';
import { USER_ROLES } from '../enums/user.enum';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public bgrData = new Subject<IAlsdeBackground>();
  public appList$ = new Subject<ApplicationWizard[]>();
  private _user = new AlsdeUser({} as AlsdeUser);
  constructor(
    public ls : LocalStorageService,
    private http: HttpService,
    private route: ActivatedRoute
  ) {
    const user = this.ls.getItem('user');
    this._user = user ? new AlsdeUser(JSON.parse(user)) : this._user;
  }

  fetchUser(data: any, rolesAccess:any){
    const decode = atob(data.split('.')[1]);
    data = JSON.parse(decode);

    this._user = new AlsdeUser({
        uid:data.preferred_username,
        name:data.name,
        email:data.email,
        preferred_username:data.preferred_username,
        sub:data.sub,
        roles: [USER_ROLES.ADMIN],
        permissions:[],
    });
    this._user.permissions = this.findPermissions(this._user.roles, rolesAccess);
    console.log('roles',this._user.roles);
    this.ls.setItem('user',JSON.stringify(this._user));
  }

  findPermissions(roles:USER_ROLES[], rolesAccess:any){
    let items = roles.reduce((permissions, role)=>{
      return permissions.concat(rolesAccess[role]);
    },[]);

    //array unique 
    return [...new Set(items)].sort();
  }


  BackgroundService() {
    return this.http.get('background?id=' + this._user.uid ).subscribe((x:any) => {
      this.bgrData.next(x);
    }, error => { this.bgrData.next(new AlsdeBackground()); });
  }

  InprogressService() {
    this.http.get('app/list').subscribe({
      next:(x:any) => {this.appList$.next(x);},
      error:() => {this.appList$.next([]);}
    })
  }

  getUser(): AlsdeUser{
    return this._user;
  }

  setUser(user:AlsdeUser){
    this._user = user;
  }

}
