<div *ngIf="docTypes && docTypes.length;else elseBlock">
  <p><b>{{btnName}}:</b><p>
    <div class="row">
      <div class="d-flex flex-wrap">
        <div class="me-3" *ngIf="docTypes.length>1">
          <mat-form-field>
            <mat-label>Select Doc Type</mat-label>
            <mat-select [(ngModel)]="selectedDocTypes.documentType" [disabled]="locked||false">
              <mat-option *ngFor="let docType of docTypes;let i = index;" [value]="docType">{{docType.displayName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="me-3" *ngIf="selectedDocTypes.documentType.subTypes.length>1">
          <mat-form-field>
            <mat-label>Select Sub Doc Type</mat-label>
            <mat-select [(ngModel)]="selectedDocTypes.documentSubType" [disabled]="locked||false">
              <mat-option *ngFor="let subDocType of selectedDocTypes.documentType.subTypes;let i = index;" [value]="subDocType">{{subDocType.displayName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="me-3">
          <button type="button" (click)="fileInputSingle.click()" 
            [disabled]="!validateDocType() || locked"
            mat-stroked-button color="accent">Upload</button>
        </div>
      </div>
    </div>
</div>
<ng-template #elseBlock>
  <div>
    <button type="button" (click)="fileInputMulti.click()" [disabled]="locked" mat-stroked-button color="accent">{{btnName}}</button>
  </div>
</ng-template>
<!-- docTypes:{{docTypes|json}} -->
<!-- selectedDocTypes:{{selectedDocTypes|json}} -->
<div *ngIf="uploadSummary.length > 0">
    <div class="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Size</th>
              <th *ngIf="!artifactUpload">Status</th>
              <th *ngIf="!locked">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of uploadSummary;let i = index;">
              <td style="word-break: break-all;">
                {{ item?.name }}
              </td>
              <td>{{ item?.size / 1024 / 1024 | number: '.2' }} MB</td>
              <td *ngIf="!artifactUpload">
                <label [ngClass]="{'text-danger':item.status=='error','text-success':item.status=='success'}" style="font-size: 14px;">
                  {{item.message}}
              </label>
              </td>
              <td data-content="Remove" class="cursor-pointer">
                <mat-icon (click)="remove(i, item.id)" style="font-size:32px; color:red;" *ngIf="!locked">close</mat-icon>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</div>
<div class="pt-2 clearfix uploadBtnSec" [hidden]="hideValidation">
    <span class="hidden-file">
        <input #fileInputSingle type="file" ng2FileSelect [uploader]="uploader" (change)="onFileSelected($event);" [accept]="appConfig.allowedMimeType" capture>
        <input #fileInputMulti  type="file" ng2FileSelect [uploader]="uploader" (change)="onFileSelected($event);" [accept]="appConfig.allowedMimeType" capture>
    </span>
    <div [ngClass]="artifactUpload ? 'fs-12': 'float-left col-md-9 col-sm-12 lh24 p-0 subtextSize'">
      Supported file formats are *.{{this.extension.join(", *.")}}. 
      <br> 
      Maximum file size limit is {{size}} MB per file. Password protected or encrypted file cannot be uploaded.
      <br>
      <div class="text-danger">Maximum upload limit is {{appConfig.maxFileCount}} file(s).</div>
    </div>
</div>