export enum BGR_STATUS{
    'VERIFIED' = 'Verified',
    'PENDING' = 'Pending',
    'FAILED' = 'Failed',
    'NO_INFO' = 'No Information',
    'REVOKED' = 'Revoked',
    'SUSPENDED' = 'Suspended',
    'DENIED' = 'Denied',
    'VOLUNTARY_SURRENDER' = 'Voluntary Surrender',
    'UNSUITABLE' = 'Unsuitable',
}

export const FAILED_BGRS = [
    BGR_STATUS.FAILED,
    BGR_STATUS.REVOKED,
    BGR_STATUS.SUSPENDED,
    BGR_STATUS.DENIED,
    BGR_STATUS.VOLUNTARY_SURRENDER,
    BGR_STATUS.UNSUITABLE,
]