import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageDialogComponent } from 'src/app/shared/partials/message-dialog/message-dialog.component';
import { Message } from '../models/message.model';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private http: HttpService
  ) { }

  openSnackBar(message: string, action?: string) {
    action = action || "Close";
    if (message) {
      this._snackBar.open(message, action, {
        duration: 13000
      })
    }
  }

  openMsgDialog(msgString: string, title?: string, isShowCancel=false) {
    let msg = new Message({} as Message);
    msg.title = title || 'Help';
    msg.text = msgString || '';
    msg.isShowCancel = isShowCancel;
    return this.dialog.open(MessageDialogComponent, {
      data: { msg: msg }, width: '500px'
    });

    // dialogRef.afterClosed().subscribe((result) => { });
  }

  openToastr(type: 'error'|'success', message: string, title?: string) {
    if(type=='success')
      this.toastr.success(message, title);
    else
      this.toastr.error(message, title);
  }

  decycle(obj:any, stack:any[] = []):any {
    if (!obj || typeof obj !== 'object')
      return obj;

    if (stack.includes(obj))
      return null;

    let s = stack.concat([obj]);

    return Array.isArray(obj)
      ? obj.map((x:any) => this.decycle(x, s))
      : Object.fromEntries(
        Object.entries(obj)
          .map(([k, v]) => [k, this.decycle(v, s)]));
  }

  sortByArray(inputArr:any[], orderedArr:string[], valueFn = (a: any) => a){
    return inputArr.sort((a, b) => orderedArr.indexOf(valueFn(a)) - orderedArr.indexOf(valueFn(b)));
  }

  onlyUnique(inputArr:any[], valueFn?: (a: any) => any) {
    inputArr.filter((value, index, array)=>array.indexOf(value) === index)
  }

  blobtoPdf(data: Blob, title: string, mime:string) {
    const fileStream = new Blob([data], {type: mime});
    const fileURL = URL.createObjectURL(fileStream);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = fileURL;
    a.target = '_blank'
    a.click();
  }

  blobtoPdfDownload(data: Blob, title: string, mime:string) {
    const fileStream = new Blob([data], {type: mime});
    const anchorTag = document.createElement('a');
    document.body.appendChild(anchorTag);
    const fileURL = URL.createObjectURL(fileStream);
    anchorTag.href = fileURL;
    anchorTag.download = title;
    anchorTag.click();
  }

  exportPdf(x:any, title: string, mime:string) {
    const blob = this.b64toBlob(x, mime);
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = String(title);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  b64toBlob(b64Data: any, contentType: string) {
    contentType = contentType || '';
    const sliceSize = 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  validateMinMax(value:string|null|undefined, min:number, max?:number){
    let minValidate = value ? value.length >= min : false;
    if(!minValidate) return false;
    if(!max) return true;
    let maxValidate = value ? value.length <= max : false;
    return maxValidate;
  }

  checkLen(event: any, length: number) {
    const val = event.target.value.replace(/ /g, '');
    if(event.keyCode != 8 && event.keyCode != 46 && val.length >= length) {
      event.preventDefault();
    }
  }

  datePipe(date:Date, format?:string){
    format = format || 'MM/dd/yyyy';
    return this.datepipe.transform(new Date(date), 'MM/dd/yyyy');
  }

  gotToFieldPrint(){
    this.openMsgDialog('Selecting this option will allow you to initiate a criminal history background check with the Alabama State Department of Education, through our vendor, Fieldprint. Certain personal information you have already provided to the ALSDE will be shared with Fieldprint once you establish an account and complete their verification process. You will be required to provide your ALSDE ID# as verification, and then you will be allowed to schedule and pay for your fingerprint appointment. Please follow the instructions on each page carefully. No background check results from an agency other than the Alabama State Department of Education will be accepted.', 'Disclaimer', true)
    .afterClosed().subscribe((result) => { 
      if(result){
        window.open(environment.externalUrl.fieldPrint, "_blank");
      }
    });
  }

  urlToBlob(url:string, data:any, title:string){
    return this.http.get(url,data, {responseType: 'blob'}).pipe(
      map((x:any)=>{
        if(x) {
          this.blobtoDownload(x, title);
          return true;
        }
        else {
          this.openSnackBar('Something went wrong with Download');
          return false;
        }
      }),
      catchError((error) => {
        this.openSnackBar('Something went wrong with Download');
        return of(false);
      })
      
    );
  }

  blobtoDownload(data: Blob, title: string) {
    const anchorTag = document.createElement('a');
    document.body.appendChild(anchorTag);
    const fileURL = URL.createObjectURL(data);
    anchorTag.href = fileURL;
    anchorTag.download = title;
    anchorTag.click();
  }
}
