import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthOktaService } from 'src/app/core/services/auth-okta.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  environment = environment;
  constructor(
    private router: Router,
    public userService: UserService,
    public authOktaService: AuthOktaService,
  ) {}

  ngOnInit(): void {}

  navigate(route:string) {
    this.router.navigate([route]);
  }

  getUser(){
    return this.userService.getUser();
  }

  logout(){
    return this.authOktaService.oktaSignOut();
  }

  login(){
    this.authOktaService.oktaSignIn()
    // window.location.href = environment.aimURL;
  }
}
