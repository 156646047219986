<mat-sidenav-container class="sidebar">
    <mat-sidenav #drawer mode="side" opened="true">
        <ul class="nav nav-pills flex-column mb-auto">
            <li>
                <a routerLink="/admin/home" class="nav-link" [routerLinkActive]="'active'"><img [src]="'../../../../assets/icons/dashboard.svg'" class="item-icon">Dashboard</a>
            </li>
            <li>
                <a routerLink="/admin/user-assignment" class="nav-link" [routerLinkActive]="'active'"><img [src]="'../../../../assets/icons/assignment_ind.svg'" class="item-icon">User Assignment</a>
            </li>
            <li>
                <a routerLink="/admin/reports" class="nav-link" [routerLinkActive]="'active'"><img [src]="'../../../../assets/icons/analytics.svg'" class="item-icon">Reports</a>
            </li>
        </ul>
    </mat-sidenav>
    <mat-sidenav-content autosize>
        <!-- <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button> -->
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>