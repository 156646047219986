import { UI_FEATURES } from "../enums/ui.enum";
import { USER_ROLES } from "../enums/user.enum";

export class IAlsdeUser {
    uid!: string;
    name?: string;
    email?: string;
    preferred_username?: string;
    sub?: string;
    roles: USER_ROLES[] = [];
    permissions: UI_FEATURES[] = [];
}

export class AlsdeUser extends IAlsdeUser{

    constructor(obj?:IAlsdeUser){
        super();
        if(obj){
            this.uid = obj?.uid;
            this.name = obj?.name;
            this.email = obj?.email;
            this.preferred_username = obj?.preferred_username;
            this.sub = obj?.sub;
            this.roles = obj?.roles || [];
            this.permissions = obj?.permissions || [];
        }
    }

    toJSON(){
        return {
            uid: this.uid,
            name : this.name,
            email: this.email,
            preferred_username : this.preferred_username,
            sub : this.sub,
            roles: this.roles,
            permissions: this.permissions,
        }
    }
}