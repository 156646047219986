export const ARTIFACT = {
    EXP: "exp",
    CER: "cer",
    PRAXIS: "praxis-assessment",
    APPFEE: "application-fee",
    PERSONAL: "personal-data",
    MILITARY: "military-declaration",
    CRIMINAL: "professional-status-criminal-history",
    ACCEPTANCE: "acceptance-signature",
    BACKGROUND: "background-check",
    EDUCATIONAL: "education-record",
    EXPERIENCE: "educational-experience",
    CITIZENSHIP: "proof-of-citizenship",
    CERTIFICATE: "upload-existing-certificate",
    TRANSCRIPTS: "transcripts",
    POWERSCHOOL: "powerschool-record",
    MILITARYFEE: "military-fee-waiver",
    UPLOADEXISTING: "upload-existing-certificate",
    COMPENTENCY: "compentency-record",
    MOREINFO: "more-info-needed",
};

export const ARTIFACTS_ORDER = [
    ARTIFACT.PERSONAL,
    ARTIFACT.CITIZENSHIP,
    ARTIFACT.MILITARY,
    ARTIFACT.CRIMINAL,
    ARTIFACT.EDUCATIONAL,
    ARTIFACT.EXPERIENCE,
    ARTIFACT.PRAXIS,
    ARTIFACT.CERTIFICATE,
    ARTIFACT.POWERSCHOOL,
    ARTIFACT.MILITARYFEE,
    ARTIFACT.UPLOADEXISTING,
    ARTIFACT.COMPENTENCY,
    ARTIFACT.APPFEE,
    ARTIFACT.BACKGROUND,
    ARTIFACT.TRANSCRIPTS,
    ARTIFACT.CER,
    ARTIFACT.EXP,
    ARTIFACT.ACCEPTANCE,
    ARTIFACT.MOREINFO,
]

export const ARTIFACTS_BY_USER = [
    ARTIFACT.PERSONAL,
    ARTIFACT.CITIZENSHIP,
    ARTIFACT.MILITARY,
    ARTIFACT.CRIMINAL,
    ARTIFACT.EDUCATIONAL,
    ARTIFACT.EXPERIENCE,
    ARTIFACT.PRAXIS,
    ARTIFACT.MOREINFO,
    ARTIFACT.ACCEPTANCE
]