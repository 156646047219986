import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject, of, forkJoin } from 'rxjs';
import { Auth, IAuth } from '../models/auth.model';
import { AppHttpClient } from './app-http-client.service';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { UtilService } from './util.service';
import { ROLE_BY_PERMISSIONS } from '../enums/ui.enum';
import { USER_ROLES } from '../enums/user.enum';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthOktaService {
  private unsubscriber: Subject<void> = new Subject<void>();
  public isAuthenticated!: boolean;
  constructor(
    private router: Router,
    public ls: LocalStorageService,
    public userService: UserService,
    private http: AppHttpClient,
    private util: UtilService,
    private authService: AuthService,
    private _oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) { }

  OktaInit() {
    this._oktaStateService.authState$.subscribe({
      next: (authState: AuthState) => {
        console.log({ authState });
        this.isAuthenticated = authState.isAuthenticated || false;
        if (authState.isAuthenticated) {
          this.validateToken(authState);
        }
      },
      error: (err) => {
        console.log({ err });
        this.util.openMsgDialog('Unable to complete login', 'We ran into a problem');
      }
    });
  }

  validateToken(auth: AuthState) {
    this.http.get("validateToken", {token:auth.idToken?.idToken})
    .subscribe({
      next:(res:any)=>{
        console.log({res});
        if (auth.idToken?.idToken) {
          let home = '/admin/home';
          this.router.navigate([home]);
          // this.authService.setAuth(new Auth({token:auth.idToken?.idToken}));
          // this.userService.fetchUser(auth.idToken?.idToken, ROLE_BY_PERMISSIONS);
          this.authService.setAuth(new Auth({token:res.token}));
          this.userService.fetchUser(res.token, ROLE_BY_PERMISSIONS);
        } else {
          this.util.openMsgDialog('Unable to complete login', 'We ran into a problem');
        }
      },
      error:()=>{
        this.util.openMsgDialog('Unable to complete login', 'We ran into a problem');
      }
    });
  }

  public async oktaSignIn(): Promise<void> {
    await this._oktaAuth.signInWithRedirect();
  }

  public async oktaSignOut(): Promise<void> {
    this.authService.logout();
  }

}
