import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Message } from '../../../core/models/message.model';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'app-message-dialog',
  templateUrl: 'message-dialog.component.html',
})
export class MessageDialogComponent {

  msg?: Message

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.msg = data.msg
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

