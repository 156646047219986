import { Component } from '@angular/core';
import { UI_FEATURES } from 'src/app/core/enums/ui.enum';

@Component({
  selector: 'app-left-bar',
  templateUrl: './left-bar.component.html',
  styleUrl: './left-bar.component.scss'
})
export class LeftBarComponent {
  UI_FEATURES = UI_FEATURES
}
