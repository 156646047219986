import { Component, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent implements OnInit {

  @Input() form: FormGroup = {} as FormGroup;
  @Input() controlName: string = '';
  @Input() type: string = 'text';
  @Input() title: string = '';
  @Input() placeholder: string = '';
  @Input() data: Array<any> = [];
  @Input() minDate: Date | string = '';
  @Input() maxDate: Date | string = new Date();
  @Input() multiple: boolean = false;

  @Output() dateChange = new EventEmitter<string | Date>();
  
  control:any;
  
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.form.get(this.controlName);
  }
  ngOnInit(): void { }

  haserror(){
    return this.control && this.control.errors && (this.control.dirty || this.control.touched)
  }

  DateChange(type: string, event: any) {
    this.dateChange.emit(event.value)
  }

}
