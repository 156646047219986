import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject, of, forkJoin } from 'rxjs';
import { Auth, IAuth } from '../models/auth.model';
import { AppHttpClient } from './app-http-client.service';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { delay, map, takeUntil } from 'rxjs/operators';
import { AlsdeUser } from '../models/alsde-user.model';
import { ROLE_BY_PERMISSIONS } from '../enums/ui.enum';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _auth = new Auth({} as Auth);
  private unsubscriber : Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    public ls: LocalStorageService,
    public userService: UserService,
    private http: AppHttpClient,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) {
    const auth = this.ls.getItem('auth');
    this._auth = auth ? new Auth(JSON.parse(auth)) : this._auth;
  }

  getAuth(): Auth {
    return this._auth;
  }

  setAuth(auth: IAuth) {
    this.ls.setItem('auth', JSON.stringify(auth));
    this._auth = new Auth(auth);
    
  }

  validateToken(authState: AuthState) {
    return forkJoin([
      this.getRolesAccessList(),
      this.getAuthenticateUser(authState)
    ]).pipe(
      map(([rolesAccess, userData]: [any,any]) => {
        if (userData && userData.Token) {
          userData.token = userData.Token;
        }
        this.setAuth(new Auth(userData));
        this.userService.fetchUser(userData.token, rolesAccess);
        return this.userService.getUser(); 
      })
    );
  }

  async logout() {
    this.ls.removeItem("auth");
    this.ls.removeItem("user");
    this._oktaAuth.signOut();
  }

  getRolesAccessList(){
    return of(ROLE_BY_PERMISSIONS).pipe(delay(1000));
  }
  
  getAuthenticateUser(authState: AuthState){
    return this.http.get('authenticate?token=' + authState.accessToken);
  }
}
