import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FileUploadModule } from '@augwit/ng2-file-upload';
import { ToastrModule } from 'ngx-toastr';

// Material
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatBadgeModule } from '@angular/material/badge'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatChipsModule } from '@angular/material/chips'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatOptionModule } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper'
import { MatMenuModule } from '@angular/material/menu'
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';

import { GrantsDirective } from '../core/directives/grants.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderComponent } from './themes/header/header.component';
import { LayoutComponent } from './themes/layout/layout.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './themes/footer/footer.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { LeftBarComponent } from './themes/left-bar/left-bar.component';
import { MatTreeModule } from '@angular/material/tree';
import { LayoutAuthComponent } from './themes/layout-auth/layout-auth.component';
import { MessageDialogComponent } from './partials/message-dialog/message-dialog.component';
import { FormControlComponent } from './partials/form-control/form-control.component';
import { FileUploadComponent } from './partials/file-upload/file-upload.component';
import { BtnActionComponent } from './partials/btn-action/btn-action.component';
import { PowerBiComponent } from '../power-bi/power-bi.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

const matImports = [
  MatTreeModule,
  MatIconModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatCheckboxModule,
  MatButtonToggleModule,
  MatBadgeModule,
  MatToolbarModule,
  MatChipsModule,
  MatExpansionModule,
  MatSnackBarModule,
  MatOptionModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatDialogModule,
  MatStepperModule,
  MatMenuModule,
  MatTabsModule,
  MatCardModule,
  MatSidenavModule,
  MatDividerModule,
  MatListModule
];

const coreImports = [
  FormsModule,
  ReactiveFormsModule,
  FileUploadModule,
  PowerBIEmbedModule
]

const components = [
  LayoutComponent,
  LayoutAuthComponent,
  HeaderComponent,
  LeftBarComponent,
  FooterComponent,

  MessageDialogComponent,
  FormControlComponent,
  FileUploadComponent,
  BtnActionComponent,
  PowerBiComponent
]

@NgModule({
  declarations: [
    GrantsDirective,
    ...components
  ],
  imports: [
    CommonModule,
    RouterModule,
    ...matImports,
    ...coreImports,
    ToastrModule.forRoot(),
  ],
  exports: [
    GrantsDirective,
    ...components,
    ...matImports,
    ...coreImports,
  ]
})
export class SharedModule { }
