import { USER_ROLES } from "./user.enum";

export enum UI_FEATURES{
    'FE1' = 'FEATURE-1',
    'FE2' = 'FEATURE-2',
    'FE3' = 'FEATURE-3',
    'FE4' = 'FEATURE-4',
    'FE5' = 'FEATURE-5',
    'FE6' = 'FEATURE-6',
};

const ROLE_BY_PERMISSIONS: any = {};
ROLE_BY_PERMISSIONS[USER_ROLES.ADMIN] = [UI_FEATURES.FE1, UI_FEATURES.FE2, UI_FEATURES.FE3, UI_FEATURES.FE4, UI_FEATURES.FE5];
ROLE_BY_PERMISSIONS[USER_ROLES.APPLICANT] = [UI_FEATURES.FE1];
ROLE_BY_PERMISSIONS[USER_ROLES.IHE] = [UI_FEATURES.FE1, UI_FEATURES.FE2];
ROLE_BY_PERMISSIONS[USER_ROLES.LEA] = [UI_FEATURES.FE1, UI_FEATURES.FE3];
ROLE_BY_PERMISSIONS[USER_ROLES.LEA3rdParty] = [UI_FEATURES.FE1, UI_FEATURES.FE3];

export {ROLE_BY_PERMISSIONS};