import { Component, ViewEncapsulation } from '@angular/core';
import { IReportEmbedConfiguration, models} from 'powerbi-client';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/services/http.service';

@Component({
  selector: 'app-power-bi',
  templateUrl: './power-bi.component.html',
  styleUrl: './power-bi.component.scss',
  encapsulation:ViewEncapsulation.None
})
export class PowerBiComponent {

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    id:environment.externalUrl.powerBIReportId,
    accessToken: undefined,
    tokenType: models.TokenType.Embed
  };

  constructor(
    private http: HttpService
  ) {
    this.http.get("powerbi-generate-token").subscribe({
      next:(res:any)=>{
        this.reportConfig.accessToken = res.token	;
      },
      error:(error:any)=>{
        console.log(error);
      }
    });
  }


}
