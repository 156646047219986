<mat-toolbar class="shadow-sm">
    <a class="log-tag" routerLink="/">
        <img title="Alabama Certification for Educators" class="header-logo desktop-only" src="../assets/img/logo-full.png" />
        <img title="Alabama Certification for Educators" class="header-logo phone-only" src="../assets/img/logo-profile.png" />
    </a>
    <span class="example-spacer"></span>
    <button mat-mini-fab color="primary" class="mx-1">
        <mat-icon>help_outline</mat-icon>
    </button>
    <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <a *ngIf="getUser().uid" [routerLink]="['/app']" mat-menu-item>
            <mat-icon color="accent">home</mat-icon>
            <span>Home</span>
        </a>
        <a *ngIf="getUser().uid" target="_blank" href="{{environment.externalUrl.alsdeUserProfile}}" mat-menu-item>
            <mat-icon color="accent">person</mat-icon>
            <span>User Profile (AIM)</span>
        </a>
        <a *ngIf="getUser().uid" [routerLink]="['/app/message-center']" mat-menu-item>
            <mat-icon color="accent">email</mat-icon>
            <span>Message Center</span>
        </a>
        <button *ngIf="getUser().uid" (click)="logout()" mat-menu-item><mat-icon color="accent">logout</mat-icon>
            <span>Logout</span>
        </button>
        <a *ngIf="!getUser().uid" [routerLink]="['/auth']" (click)="login()" mat-menu-item>
            <mat-icon color="accent">login</mat-icon>
            <span>Login</span>
        </a>
    </mat-menu>
</mat-toolbar>